module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-70265328-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intercom-spa/gatsby-browser.js'),
      options: {"plugins":[],"app_id":"j0bj8j3y","include_in_development":false,"delay_timeout":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicons/logo_e1transit_16x16.png","icons":[{"src":"src/images/favicons/logo_e1transit_16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/images/favicons/logo_e1transit_32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/images/favicons/logo_e1transit_180x180.png","sizes":"180x180","type":"image/png"},{"src":"src/images/favicons/logo_e1transit_192x192.png","sizes":"192x192","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cc39714663320d92d2dbb4d74bde4b07"},
    }]
